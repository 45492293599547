import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/en/chapter_4/sub_13/slide1';

import WrapperMobile from 'src/slides/mobile/en/chapter_4/sub_13/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from '../../../components/_shared/seo';

// desktop

const allSlides = [
  <Slide1 />,
];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="What happened after the victory | The Battle of Warsaw " description="The defence of Lvov, the Battle of Zadwórze, the Battle of the Niemen River - the victory of Warsaw did not end the war." lang="en" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

// mobile


const Mobile = () => (
  <>
    <SEO title="What happened after the victory | The Battle of Warsaw " description="The defence of Lvov, the Battle of Zadwórze, the Battle of the Niemen River - the victory of Warsaw did not end the war." lang="en" />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};
export default Page;
